import qs from 'qs';
import { setMobileNativeEvent } from './setMobileNativeEvent';

export function setLocalData(key: string, value: string) {
  try {
    localStorage.setItem(key, value);
  } catch {
    // console.log("error", error)
  }
}

export function removeLocalData(key: string) {
  try {
    localStorage.removeItem(key);
  } catch {
    // console.log("error", error)
  }
}

export function getLocalData(key: string) {
  try {
    const data = localStorage.getItem(key);
    return data;
  } catch {
    // console.log("error", error)
  }
}

export function isValidValue(value: any) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return false;
  }
  return true;
}

export function isCheckValueAndSetParameters(parameters: string, value: string) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return '';
  }
  return parameters + value;
}

export function hasProperty(object: any, key: string) {
  if (Array.isArray(object) || typeof key !== 'string' || !object) {
    return false;
  }
  return Object.prototype.hasOwnProperty.call(object, key);
}

export function isValidObject(items: any) {
  if (Object.keys(items).length > 0) {
    return true;
  }
  return false;
}

export function getValueFromObject(object: any, key: string) {
  if (hasProperty(object, key) && isValidValue(object[key])) {
    return object[key];
  }
  return '';
}

export function formatMemory(value: number) {
  if (+value >= 1024) return { value: value / 1024, unit: 'tb' };
  return { value, unit: 'gb' };
}

export function formatUrlMemory(data: string) {
  if (!data) return {};
  const [value, unit] = data.split('-');
  return { value, unit };
}

export const getErrorMessage = (error: any) => {
  if (error.response?.request?.response?.data?.message) {
    return error.response.request.response.data.message;
  }
  if (error.message) return error.message;
  return null;
};

export const createStoreError = (error: any) => {
  if (!error) return null;
  return {
    status: error.response?.status,
    message: getErrorMessage(error),
  };
};
export function checkType(data: any, type: string) {
  return Object.values(data).includes(type);
}

export function getRoute(route: string, isMobile?: boolean, params?: any) {
  const query = params && qs.stringify(params, { skipNulls: true });
  if (isMobile) return `/mobile${route}${query ? `?${query}` : ''}`;
  return `${route}${query ? `?${query}` : ''}`;
}

export const parseToken = (token: string) => {
  if (!token) return null;
  return JSON.parse(atob(token.split('.')[1]));
};

export function getTokenUserId(key: string) {
  try {
    const token = localStorage.getItem(key);
    if (!token) return null;
    const data = parseToken(token);
    return data.userId;
  } catch {
    return null;
  }
}

export function checkIncludes(data: any, ...items: any) {
  return items.some((item: any) => data?.includes(item));
}

// export function getFormDataObj(obj) {
//   let formData = new FormData()
//   for (let i in obj) {
//     if (obj.hasOwnProperty(i)) {
//       let element = obj[i];
//       formData.append(i, element)
//     }
//   }
//   return formData
// }

export function setPageFrom(type: string) {
  const data = { ...window.location, type };
  localStorage.setItem('page_from', JSON.stringify(data));
}

export function getPageFrom() {
  return JSON.parse(localStorage.getItem('page_from'));
}

export const getMobilePageFrom = () => {
  const result = setMobileNativeEvent('getPageFrom');
  console.log('mobile page_from: %o', result); // eslint-disable-line

  if (result) {
    const data = { type: result };
    localStorage.setItem('page_from', JSON.stringify(data));
  }
};

export const getSearchWithoutNulls = (params: any) => {
  return qs.stringify(params, { skipNulls: true });
};

export const getSettings = () => {
  const query = qs.parse(window.location.search?.replace(/^\?/, ''));
  if (query.os) localStorage.setItem('CIVI_MOBILE_OS', query.os as string);
  if (query.version) localStorage.setItem('CIVI_MOBILE_APP_VERSION', query.version as string);
  const os = localStorage.getItem('CIVI_MOBILE_OS');
  const version = localStorage.getItem('CIVI_MOBILE_APP_VERSION');
  const channel = query.channel as string;
  const new_action_links = query.new_action_links as string;
  return { os, version, channel, new_action_links };
};

export const getProductImages = (product: any) => {
  if (!product?.images?.length) return [];
  if ((product.funcRating !== 100 || product.visualRating !== 100) && product.images.length > 1) {
    return product.images?.slice(1);
  }
  return product.images;
};
